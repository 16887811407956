import logo from "../images/logo.png";
import dash1 from "../images/dashboard_img1.jpg";
import dash2 from "../images/dashboard_img2.jpg";
import img2 from "../images/img2.png";


import c1 from "../images/courses/c1.jpg";
import c2 from "../images/courses/c2.jpg";
import c3 from "../images/courses/c3.jpg";
import c4 from "../images/courses/c4.jpg";
import c5 from "../images/courses/c5.jpg";
import c6 from "../images/courses/c6.jpg";
import c7 from "../images/courses/c7.jpg";
import c8 from "../images/courses/c8.jpg";




const programs = [
    {
        "name": "Web Development",
        "description": "BlueHill's offers you the opportunity to enroll in Web Development programs at this platform.",
        "courses": 7,
        card_Style: { bgcolor: "#e8f7f5", fcolor: "#4fb79d", circle: "#d3f0ec" }
    },
    {
        "name": "App Development",
        "description": "BlueHill's  offers you the opportunity to enroll in App Development programs at this platform.",
        "courses": 4,
        card_Style: { bgcolor: "#fdecef", fcolor: "#f17066", circle: "#fbdbe1" }
    },
    {
        "name": "Java Programming",
        "description": "BlueHill's  offers you the opportunity to enroll in Java Programming programs at this platform.",
        "courses": 8,
        card_Style: { bgcolor: "#eceffc", fcolor: "#4469e8", circle: "#dbe1f9" }
    },
    {
        "name": "Python Programming",
        "description": "BlueHill's  offers you the opportunity to enroll in Python Programming programs at this platform.",
        "courses": 6,
        card_Style: { bgcolor: "#fef7e8", fcolor: "#f8b943", circle: "#fdf0d3" }
    }
]



const Internship = [
    {
        "name": "Web Development",
        "level": "Beginner to Advanced",
        "duration": "4 Weeks",
        "projects": 4,
        "students": "40.5K",
        "registration": "Register",
        "additional_info": {
            "full_name": "Web Development Internship",
            "duration_extended": "3 Months"
        }
    },
    {
        "name": "App Development Internship",
        "level": "Beginner to Advanced",
        "duration": "4 Weeks",
        "projects": 4,
        "students": "18.4K",
        "registration": "Register",
        "additional_info": {
            "full_name": "App Development Internship",
            "duration_extended": "1 Month"
        }
    },
    {
        "name": "Java Development Internship",
        "level": "Beginner to Advanced",
        "duration": "4 Weeks",
        "projects": 4,
        "students": "14.9K",
        "registration": "Register"
    }
]

const courses = [
    {
        img: c1,
        "name": "HTML CSS JAVASCRIPT JQUERY",
        "description": ["4 Live Projects", "10 hour demand lecture", "Basic to Advance", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1200",
        "currentPrice": "999"
    },
    {
        img: c2,
        "name": "PHP",
        "description": ["3 Live Projects", "8 hour demand lecture", "Beginner to Intermediate", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1500",
        "currentPrice": "1099"
    },
    {
        img: c3,
        "name": "MySQL",
        "description": ["4 Live Projects", "12 hour demand lecture", "Basic to Advanced", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1400",
        "currentPrice": "1199"
    },
    {
        img: c4,
        "name": "Node.js",
        "description": ["4 Live Projects", "15 hour demand lecture", "Basic to Advanced", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1600",
        "currentPrice": "1299"
    },
    {
        img: c5,
        "name": "MongoDB",
        "description": ["4 Live Projects", "9 hour demand lecture", "Beginner to Intermediate", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1300",
        "currentPrice": "999"
    },
    {
        img: c6,
        "name": "React",
        "description": ["6 Live Projects", "20 hour demand lecture", "Basic to Advanced", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1800",
        "currentPrice": "1499"
    },
    {
        img: c7,
        "name": "Next.js",
        "description": ["4 Live Projects", "18 hour demand lecture", "Intermediate to Advanced", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "1700",
        "currentPrice": "1399"
    },
    {
        img: c8,
        "name": "GitHub",
        "description": ["2 hour demand lecture", "Beginner to Advanced", "Handwritten Notes", "Weekly Quizzes"],
        "prevPrice": "600",
        "currentPrice": "399"
    }
];

const popularity = [
    {
        number: 12.1,
        symbol: "K",
        title: "Registrations",
        card_Style: { bgcolor: "#e8f7f5", fcolor: "#4fb79d", circle: "#d3f0ec" }
    },
    {
        number: 10.4,
        symbol: "K",
        title: "Participants",
        card_Style: { bgcolor: "#fdecef", fcolor: "#f17066", circle: "#fbdbe1" }
    },
    {
        number: 98.96,
        symbol: "%",
        title: "Satisfaction Rate",
        card_Style: { bgcolor: "#eceffc", fcolor: "#4469e8", circle: "#dbe1f9" }
    },
    {
        number: 19,
        symbol: "+",
        title: "Countries",
        card_Style: { bgcolor: "#fef7e8", fcolor: "#f8b943", circle: "#fdf0d3" }
    }
];




export { logo, dash1, dash2, img2, programs, Internship, courses, popularity }