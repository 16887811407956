import React, { useState } from 'react';
import { logo } from '../Config/Api';
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { UserState } from '../UserContext';
import axios from 'axios';
import "sweetalert2";
import Swal from 'sweetalert2';

const Navbar = () => {

    const { searchClick, setSearchClick } = UserState();


    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose
    } = useDisclosure();

    const {
        isOpen: isModalOpen,
        onOpen: onModalOpen,
        onClose: onModalClose
    } = useDisclosure();


    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)



    const [Name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);




    const messageHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.post(process.env.REACT_APP_MAIL_LINK, { Name, email, phone, message });
            setLoading(false);

            // Close the modal after successful login
            onModalClose();

            Swal.fire({
                title: "success!",
                text: "Message Send successfull!",
                icon: "success"
            });
        } catch (error) {
            setLoading(false);
        }
    };



    return (
        <>
            <nav className='navbar'>
                <div className='container'>
                    <NavLink to="/" className='d-flex flex-column justify-content-center' style={{ width: "6rem" }}>

                        <div className='d-flex '>
                            <img style={{ width: "2rem" }} src={logo} alt={logo} />
                            <span style={{ fontSize: "1rem", color: 'orangered' }}> Lue Hill's</span>
                        </div>

                    </NavLink>
                    <ul id='dasboard_option'>
                        <li><NavLink to="/" className='pt-2'>Home</NavLink></li>
                        <li><NavLink to="/internship" className='pt-2'>Opportunities</NavLink></li>
                        <li><NavLink to="/cources" className='pt-2'>Cources</NavLink></li>
                        <li><NavLink to="/verify-certificate" className='pt-2'>Verifyication</NavLink></li>
                        <li> <NavLink to="/products" className='pt-2'>Products</NavLink></li>
                    </ul>
                    <div className='d-flex gap-4'>
                        <svg onClick={() => { setSearchClick(!searchClick) }} xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 50 50" style={{ cursor: "pointer" }}>
                            <path fill="black" d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13m0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11" />
                            <path fill="black" d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" style={{ cursor: "pointer" }}>
                            <path fill="black" d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m-9-1a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M18 6H4.27l2.55 6H15c.33 0 .62-.16.8-.4l3-4c.13-.17.2-.38.2-.6a1 1 0 0 0-1-1m-3 7H6.87l-.77 1.56L6 15a1 1 0 0 0 1 1h11v1H7a2 2 0 0 1-2-2a2 2 0 0 1 .25-.97l.72-1.47L2.34 4H1V3h2l.85 2H18a2 2 0 0 1 2 2c0 .5-.17.92-.45 1.26l-2.91 3.89c-.36.51-.96.85-1.64.85" />
                        </svg>

                        <Button id='signup' colorScheme='orange' variant='outline' onClick={onModalOpen}>
                            Contact us
                        </Button>

                        <svg onClick={onDrawerOpen} id='menubar' xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 512 512">
                            <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352" />
                        </svg>
                    </div>
                </div>
            </nav>



            {/* Drawer  */}
            <Drawer placement="left" onClose={onDrawerClose} isOpen={isDrawerOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px' padding='1.5rem'>
                        <Button colorScheme='orange' variant='solid' width='full' onClick={() => { onModalOpen(); onDrawerClose(); }}>
                            Contact us
                        </Button>
                    </DrawerHeader>
                    <DrawerBody padding='1.5rem'>
                        <Accordion allowToggle>

                            <AccordionItem border='none'>
                                <NavLink to="/">
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left' >
                                            Home
                                        </Box>
                                    </AccordionButton>
                                </NavLink>
                            </AccordionItem>


                            <AccordionItem border='none'>
                                <h2>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left' >
                                            opportunities
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p><NavLink to="/internship">Internship</NavLink></p>
                                    <p><NavLink to="/jobs">Job's</NavLink></p>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem border='none'>
                                <h2>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'>
                                            Courses
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p><NavLink to="/cources">Web Development</NavLink></p>
                                    <p><NavLink to="/cources">App Development</NavLink></p>
                                    <p><NavLink to="/cources">Data Analyst</NavLink></p>
                                </AccordionPanel>
                            </AccordionItem>



                            <AccordionItem border='none'>
                                <NavLink to="/verify-certificate">
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left' >
                                            Verification
                                        </Box>
                                    </AccordionButton>
                                </NavLink>
                            </AccordionItem>


                            <AccordionItem border='none'>
                                <h2>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left' >
                                            <NavLink to="/products">Products</NavLink>
                                        </Box>
                                    </AccordionButton>
                                </h2>
                            </AccordionItem>
                        </Accordion>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>



            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isModalOpen}
                onClose={onModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='w-100'>
                            <h5 className='text-center'>Send <span style={{ color: "orange" }}>Message</span></h5>
                        </div>

                        <form onSubmit={messageHandler}>
                            <div className='my-2'>
                                <label htmlFor="Name">Name:</label>
                                <input
                                    className='form-control'
                                    type="text"

                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your Name"
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="Email">Email:</label>
                                <input
                                    className='form-control'
                                    type="text"

                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your Email"
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    className='form-control'
                                    type="text"

                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone no."
                                    required
                                />
                            </div>
                            <div className='my-2'>
                                <label htmlFor="message">Message:</label>
                                <textarea
                                    className='form-control'
                                    type="text"

                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Message"
                                    required
                                />
                            </div>
                            <Button type='submit' variant="outline" colorScheme='orange' className='my-4' style={{ float: "right" }} isLoading={loading} >Send</Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>


        </>
    )
}

export default Navbar
