import pr1 from "../images/products/pr1.jpg";
import pr2 from "../images/products/pr2.png";
import pr3 from "../images/products/pr3.png";
import pr4 from "../images/products/pr4.jpg";
import pr5 from "../images/products/pr5.png";
import pr6 from "../images/products/pr6.png";
import pr7 from "../images/products/pr7.jpg";
import pr8 from "../images/products/pr8.webp";
import pr9 from "../images/products/pr9.png";



export const products = [
    {
        "title": "Real Estate Websites",
        "price": "₹19,280.00",
        "image": pr1
    },
    {
        "title": "Blogs and News Sites",
        "price": "₹11,499.00",
        "image": pr2
    },
    {
        "title": "ERP Portal",
        "price": "₹8,999.00",
        "image": pr3
    },
    {
        "title": "Portfolio Websites",
        "price": "₹7,780.00",
        "image": pr4
    },
    {
        "title": "Educational Websites",
        "price": "₹34,200.00",
        "image": pr5
    },
    {
        "title": "Business and Corporate Sites",
        "price": "₹11,980.00",
        "image": pr6
    },
    {
        "title": "Landing Pages and Microsites",
        "price": "₹9,999.00",
        "image": pr7
    },
    {
        "title": "Community and Social Networks",
        "price": "₹1,89,000.00",
        "image": pr8
    },
    {
        "title": "Booking and Appointment Websites",
        "price": "₹36,800.00",
        "image": pr9
    }
]