export const competitions = [
    {
        "title": "Startup Pitch Competition",
        "description": "A platform for students to pitch their startup ideas to a panel of judges.",
        "location": "Online",
        "registrationFee": "free",
        "prizes": "Certificate and goodies and internship opprochunity",
        "mentor": {
            "name": "Naveen Kumar",
            "availableTime": "Pitch coaching available",
            "chargesPerHour": 190
        },
        "start_date": "2024-08-10",
        "end_date": "2024-9-10"
    }
]