import React from 'react'
import { mentorshipPlans } from '../Config/mentorship'
import {
    Box,
    Heading,
    Text,
    VStack,
    Button,
    Grid,
    GridItem,
} from '@chakra-ui/react';

const Mentorship = () => {

    
    return (
        <>
            <Box p={8}>
                <Heading as="h2" mb={6} style={{ textAlign: "center" }}>
                    Mentorship <span style={{ color: "orange" }}>Plans</span>
                </Heading>
                <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
                    {mentorshipPlans.map((plan, index) => (
                        <GridItem key={index} borderWidth={1} borderRadius="lg" p={4} boxShadow="md">
                            <VStack spacing={4}>
                                <Heading size="md" style={{ textAlign: "center" }}>{plan.title}</Heading>
                                <Text fontWeight="bold">Price: ₹{plan.price}</Text>
                                <Text>Duration: {plan.duration}</Text>
                                <Text>{plan.description}</Text>
                                <Text>
                                    Mentor: {plan.mentor.name} <br />
                                    Available: {plan.mentor.availableTime} <br />
                                    Charges per hour: ₹{plan.mentor.chargesPerHour} <br />
                                    Interview Preparation Charges: ₹{plan.interviewPreparationCharges}
                                </Text>
                                <Button colorScheme="teal">Enroll Now</Button>
                            </VStack>
                        </GridItem>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default Mentorship
