const AvailInternship = [
    {
        "name": "Web Development",
        "description": "BlueHill's offers you the opportunity to enroll in Web Development programs at this platform.",
        card_Style: { bgcolor: "#e8f7f5", fcolor: "#4fb79d", circle: "#d3f0ec" }
    },
    {
        "name": "App Development",
        "description": "BlueHill's  offers you the opportunity to enroll in App Development programs at this platform.",
        card_Style: { bgcolor: "#fdecef", fcolor: "#f17066", circle: "#fbdbe1" }
    },
    {
        "name": "Java Programming",
        "description": "BlueHill's  offers you the opportunity to enroll in Java Programming programs at this platform.",
        card_Style: { bgcolor: "#eceffc", fcolor: "#4469e8", circle: "#dbe1f9" }
    },
    {
        "name": "Python Programming",
        "description": "BlueHill's  offers you the opportunity to enroll in Python Programming programs at this platform.",
        card_Style: { bgcolor: "#fef7e8", fcolor: "#f8b943", circle: "#fdf0d3" }
    }
]

export { AvailInternship }
