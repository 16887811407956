import { Button, Input } from '@chakra-ui/react'
import React from 'react'

const Searchbar = () => {
    return (
        <div className='d-flex flex-row gap-4'>
            <Input type="text" placeholder='Search here...' />
            <Button varient="outline" colorScheme='orange'>Search</Button>
        </div>
    )
}

export default Searchbar
