import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Heading } from '@chakra-ui/react';
import signature from "../images/signature.jpg";
import qr from "../images/qr-code.png";
import msme from "../images/msme.png";
import logo from "../images/logo.png"
import badge from "../images/badge.png"
import { certificates } from '../Config/Certificate';

const VerifyCert = () => {
    const [email, setEmail] = useState('');
    const [certNo, setCertNo] = useState('');
    const [certificateData, setCertificateData] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const foundCertificate = certificates.find((cert) =>
            cert.email === email && cert.certificateNumber.toLowerCase() === certNo.toLowerCase()
        );
        console.log(foundCertificate)

        if (foundCertificate) {
            setCertificateData(foundCertificate);
        } else {
            alert('Certificate not found');
            setCertificateData(null);
        }
    };

    const printCertificate = () => {
        const printSection = document.getElementById("certificate-section").innerHTML;
        const originalContent = document.body.innerHTML;
        document.body.innerHTML = printSection;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };
    function getDuration(startDate, endDate) {
        // Convert the input dates to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Ensure the end date is greater than the start date
        if (end < start) {
            throw new Error("End date must be after the start date.");
        }

        // Calculate the difference in milliseconds
        const timeDiff = end - start;

        // Calculate the number of days
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Determine the appropriate duration
        const weeks = Math.floor(daysDiff / 7);
        const remainingDays = daysDiff % 7; // Remaining days after weeks
        const months = Math.floor(daysDiff / 30); // Approximate month length

        if (daysDiff < 7) {
            return `${daysDiff} days`;
        } else if (daysDiff >= 7 && daysDiff < 28) {
            if (weeks === 1) {
                return "1 week" + (remainingDays > 0 ? ` and ${remainingDays} days` : "");
            } else {
                return `${weeks} weeks` + (remainingDays > 0 ? ` and ${remainingDays} days` : "");
            }
        } else if (daysDiff >= 28 && daysDiff < 60) {
            return `${months} month${months > 1 ? 's' : ''}`;
        } else {
            return `${months} month${months > 1 ? 's' : ''}`; // For durations greater than 2 months
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit} className="w-100 d-flex justify-content-center my-5 mt-5">
                <Card className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-lg-4">
                    <CardHeader>
                        <Heading size="md" className="text-center">
                            Verify And Print <span style={{ color: 'orange' }}>Certificate</span>
                        </Heading>
                    </CardHeader>
                    <CardBody className="d-flex flex-wrap justify-content-evenly">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Email Id:
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="certNo" className="form-label">Certificate No:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="certNo"
                                value={certNo}
                                onChange={(e) => setCertNo(e.target.value)}
                                required
                            />
                            <div id="certHelp" className="form-text">Ensure the Certificate number is correct.</div>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Button type="submit" className="ms-auto" variant="outline" colorScheme="orange">Fetch Certificate</Button>
                    </CardFooter>
                </Card>
            </form>

            <div style={{ display: "flex", justifyContent: "center" }}>
                {certificateData && (
                    <>
                        <div id="certificate-section" className="certificate-container mt-5">
                            <div className="certificate card" style={{ position: "relative", width: "800px", margin: "0 auto", padding: "2rem", borderRadius: "10px", boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                                <div className="certificate-header text-center">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img className="logo" src={logo} alt="Logo" />
                                        <span style={{ color: "orangered", fontSize: "3rem", marginLeft: "1rem" }}>Lue Hill's</span>
                                    </div>
                                    <h1 className="certificate-title" style={{ fontSize: "2.5rem", margin: "1rem 0" }}>CERTIFICATE OF COMPLETION</h1>
                                </div>

                                <div className="certificate-body text-center">
                                    <h5 className="certificate-subtitle" style={{ fontSize: "1.5rem" }}>This is to certify that</h5>
                                    <h2 className="certificate-recipient" style={{ fontSize: "2rem", margin: "1rem 0" }}>{certificateData.name}</h2>
                                    <p className="certificate-description" style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>
                                        has successfully completed {getDuration(certificateData.start, certificateData.end)} of a virtual internship program in <strong>{certificateData.course}</strong>
                                        with wonderful remarks at BLUEHILLS from {certificateData.start} to {certificateData.end}. We were truly amazed by
                                        his/her showcased skills and invaluable contributions to the tasks and projects throughout the internship.
                                    </p>
                                </div>

                                <div className="certificate-footer d-flex justify-content-between mt-4">
                                    <div className="footer-section text-center">
                                        <img className="qr-code" src={qr} alt="QR Code" style={{ maxWidth: "100px" }} />
                                    </div>

                                    <div className="footer-section text-center">
                                        <img className="signature" src={signature} alt="Signature" style={{ maxWidth: "150px" }} />
                                        <hr className="line" />
                                        <p>Founder</p>
                                    </div>

                                    <div className="footer-section text-center">
                                        <p className="issue-label">ISSUE DATE</p>
                                        <hr className="line" />
                                        <p>Date: {certificateData.issueDate}</p>
                                    </div>

                                    <div className="footer-section text-center">
                                        <img className="msme-logo" src={msme} alt="MSME" style={{ maxWidth: "100px" }} />
                                    </div>
                                </div>

                                <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
                                    <p>Certificate No: <span>{certificateData.certificateNumber}</span></p>
                                </div>

                                <div style={{ width: "8rem", position: "absolute", top: "1rem", right: "1rem" }}>
                                    <img className='w-100' src={badge} alt="badge" />
                                </div>
                            </div>

                            <div className="print-button-container text-center my-4">
                                <Button variant="outline" colorScheme='orange' onClick={printCertificate}>Print Certificate</Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default VerifyCert;
