import React from 'react'

const Error = () => {
    return (
        <div>
            <h1 className='text-black mt-5'>page not found</h1>
        </div>
    )
}

export default Error
