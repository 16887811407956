export const certificates = [
    {
        "name": "Deepak Chaubey",
        "certificateNumber": "CERT1001",
        "start": "20-Jan-2024",
        "end": "20-Feb-2024",
        "course": "Web Development",
        "project": "Designed homepage of the railway website",
        "email": "chaubeydeepak343@gmail.com",
        "phone": 7078945673,
        "issueDate": "21-Feb-2024"  // Adjusted to be after end date
    },
    {
        "name": "Anjali Sharma",
        "certificateNumber": "CERT1002",
        "start": "15-Feb-2024",
        "end": "15-Mar-2024",
        "course": "Data Science",
        "project": "Analyzed customer data for a retail store",
        "email": "anjali.sharma@example.com",
        "phone": 9876543210,
        "issueDate": "16-Mar-2024"  // Adjusted to be after end date
    },
    {
        "name": "Rohit Verma",
        "certificateNumber": "CERT1003",
        "start": "01-Mar-2024",
        "end": "01-Apr-2024",
        "course": "Digital Marketing",
        "project": "Created a social media campaign for a startup",
        "email": "rohit.verma@example.com",
        "phone": 9123456789,
        "issueDate": "02-Apr-2024"  // Adjusted to be after end date
    },
    {
        "name": "Meera Gupta",
        "certificateNumber": "CERT1004",
        "start": "10-Jan-2024",
        "end": "10-Feb-2024",
        "course": "Graphic Design",
        "project": "Designed a logo for a fashion brand",
        "email": "meera.gupta@example.com",
        "phone": 8901234567,
        "issueDate": "11-Feb-2024"  // Adjusted to be after end date
    },
    {
        "name": "Sahil Khan",
        "certificateNumber": "CERT1005",
        "start": "05-Apr-2024",
        "end": "05-May-2024",
        "course": "Machine Learning",
        "project": "Built a predictive model for housing prices",
        "email": "sahil.khan@example.com",
        "phone": 9567890123,
        "issueDate": "06-May-2024"  // Adjusted to be after end date
    },
    {
        "name": "Priya Nair",
        "certificateNumber": "CERT1006",
        "start": "20-Feb-2024",
        "end": "20-Mar-2024",
        "course": "Cyber Security",
        "project": "Secured a web application from potential threats",
        "email": "priya.nair@example.com",
        "phone": 8765432109,
        "issueDate": "21-Mar-2024"  // Adjusted to be after end date
    },
    {
        "name": "Karan Mehta",
        "certificateNumber": "CERT1007",
        "start": "01-May-2024",
        "end": "01-Jun-2024",
        "course": "Blockchain Development",
        "project": "Developed a smart contract for digital assets",
        "email": "karan.mehta@example.com",
        "phone": 7654321098,
        "issueDate": "02-Jun-2024"  // Adjusted to be after end date
    },
    {
        "name": "Aditi Rao",
        "certificateNumber": "CERT1008",
        "start": "25-Jan-2024",
        "end": "25-Feb-2024",
        "course": "UI/UX Design",
        "project": "Redesigned the user interface of an e-commerce app",
        "email": "aditi.rao@example.com",
        "phone": 6543210987,
        "issueDate": "26-Feb-2024"  // Adjusted to be after end date
    },
    {
        "name": "Vikram Singh",
        "certificateNumber": "CERT1009",
        "start": "10-Mar-2024",
        "end": "10-Apr-2024",
        "course": "Cloud Computing",
        "project": "Deployed a scalable web app on AWS",
        "email": "vikram.singh@example.com",
        "phone": 5432109876,
        "issueDate": "11-Apr-2024"  // Adjusted to be after end date
    },
    {
        "name": "Sneha Joshi",
        "certificateNumber": "CERT1010",
        "start": "15-Apr-2024",
        "end": "15-May-2024",
        "course": "Full Stack Development",
        "project": "Built a task management application",
        "email": "sneha.joshi@example.com",
        "phone": 4321098765,
        "issueDate": "16-May-2024"  // Adjusted to be after end date
    },
    {
        "name": "Aman Gupta",
        "certificateNumber": "CERT1011",
        "start": "05-Feb-2024",
        "end": "05-Mar-2024",
        "course": "Artificial Intelligence",
        "project": "Created an image recognition model",
        "email": "aman.gupta@example.com",
        "phone": 3210987654,
        "issueDate": "06-Mar-2024"  // Adjusted to be after end date
    },
    {
        "name": "Simran Kaur",
        "certificateNumber": "CERT1012",
        "start": "01-Mar-2024",
        "end": "01-Apr-2024",
        "course": "Big Data Analytics",
        "project": "Analyzed and visualized sales data",
        "email": "simran.kaur@example.com",
        "phone": 2109876543,
        "issueDate": "02-Apr-2024"  // Adjusted to be after end date
    },
    {
        "name": "Rajesh Patel",
        "certificateNumber": "CERT1013",
        "start": "25-Feb-2024",
        "end": "25-Mar-2024",
        "course": "DevOps",
        "project": "Automated deployment for a web application",
        "email": "rajesh.patel@example.com",
        "phone": 1098765432,
        "issueDate": "26-Mar-2024"  // Adjusted to be after end date
    },
    {
        "name": "Nisha Yadav",
        "certificateNumber": "CERT1014",
        "start": "20-Jan-2024",
        "end": "20-Feb-2024",
        "course": "Python Programming",
        "project": "Developed a data scraping tool",
        "email": "nisha.yadav@example.com",
        "phone": 9876543210,
        "issueDate": "21-Feb-2024"  // Adjusted to be after end date
    },
    {
        "name": "Ashwin Kumar",
        "certificateNumber": "CERT1015",
        "start": "10-Feb-2024",
        "end": "10-Mar-2024",
        "course": "Game Development",
        "project": "Created a 2D platformer game",
        "email": "ashwin.kumar@example.com",
        "phone": 8765432109,
        "issueDate": "11-Mar-2024"  // Adjusted to be after end date
    },
    {
        "name": "Pooja Singh",
        "certificateNumber": "CERT1016",
        "start": "15-Mar-2024",
        "end": "15-Apr-2024",
        "course": "Mobile App Development",
        "project": "Developed an Android app for fitness tracking",
        "email": "pooja.singh@example.com",
        "phone": 7654321098,
        "issueDate": "16-Apr-2024"  // Adjusted to be after end date
    },
    {
        "name": "Ravi Shankar",
        "certificateNumber": "CERT1017",
        "start": "05-Apr-2024",
        "end": "05-May-2024",
        "course": "IoT",
        "project": "Built a smart home lighting system",
        "email": "ravi.shankar@example.com",
        "phone": 6543210987,
        "issueDate": "06-May-2024"  // Adjusted to be after end date
    },
    {
        "name": "Sunita Das",
        "certificateNumber": "CERT1018",
        "start": "01-Jan-2024",
        "end": "01-Feb-2024",
        "course": "Robotics",
        "project": "Developed test cases for a web application",
        "email": "ritika.bhatt@example.com",
        "phone": 3210987654,
        "issueDate": "04-Feb-2024"
    },
    {
        "name": "Kunal Mehra",
        "certificateNumber": "CERT1021",
        "start": "15-Apr-2024",
        "end": "15-May-2024",
        "course": "Blockchain Fundamentals",
        "project": "Built a decentralized application on Ethereum",
        "email": "kunal.mehra@example.com",
        "phone": 2109876543,
        "issueDate": "14-Apr-2024"
    },
    {
        "name": "Anaya Sen",
        "certificateNumber": "CERT1022",
        "start": "25-Mar-2024",
        "end": "25-Apr-2024",
        "course": "Advanced Web Development",
        "project": "Created a responsive website using React",
        "email": "anaya.sen@example.com",
        "phone": 1098765432,
        "issueDate": "24-Mar-2024"
    },
    {
        "name": "Vivek Sharma",
        "certificateNumber": "CERT1023",
        "start": "10-May-2024",
        "end": "10-Jun-2024",
        "course": "Cybersecurity Essentials",
        "project": "Conducted a security audit for a small business",
        "email": "vivek.sharma@example.com",
        "phone": 9876543210,
        "issueDate": "09-May-2024"
    },
    {
        "name": "Tina Roy",
        "certificateNumber": "CERT1024",
        "start": "01-Apr-2024",
        "end": "01-May-2024",
        "course": "Front-End Development",
        "project": "Developed a user-friendly interface for a web app",
        "email": "tina.roy@example.com",
        "phone": 8765432109,
        "issueDate": "31-Mar-2024"
    },
    {
        "name": "Neel Patel",
        "certificateNumber": "CERT1025",
        "start": "15-Jun-2024",
        "end": "15-Jul-2024",
        "course": "Data Science Bootcamp",
        "project": "Built a machine learning model for predictive analytics",
        "email": "neel.patel@example.com",
        "phone": 7654321098,
        "issueDate": "14-Jun-2024"
    }
];
