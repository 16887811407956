import { Button, ButtonGroup, Card, CardBody, CardFooter, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { courses } from '../Config/Api'

const Cources = () => {
    return (
        <div className='mx-2 py-4' >
            <div div className='container pt-lg-4'>
                <h4 className='text-center'>POPULAR <span style={{ color: "orange" }}>COURSES</span></h4>
                <h2 className='text-center my-5'>Get Started with the Courses</h2>

                <div className='d-flex flex-wrap gap-5 justify-content-center'>
                    {courses.map((curElm, index) => {
                        return (
                            <>
                                <Card key={index} style={{ width: "18rem" }}>
                                    <div style={{ position: "relative" }}>
                                        <img className="w-100" style={{ opacity: 0.6, objectFit: "cover", width: "100%", height: "100%", }} src={curElm.img} alt={curElm.img}
                                        />
                                        <div
                                            style={{
                                                position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.4)", // Black overlay with transparency
                                            }}
                                        ></div>
                                        <Heading
                                            style={{
                                                position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", // Ensures text is visible
                                            }}
                                            className="text-center"
                                            size="md"
                                        >
                                            {curElm.name}
                                        </Heading>
                                    </div>

                                    <CardBody>
                                        <div>
                                            {curElm.description.map((des, index) => {
                                                return (
                                                    <>
                                                        <Text key={index}>{des}</Text>
                                                    </>
                                                )
                                            })}
                                        </div>

                                        <div className='d-flex gap-2'>
                                            <p style={{ textDecoration: 'line-through' }}>{curElm.prevPrice}</p>
                                            <p>{curElm.currentPrice}</p>
                                        </div>
                                    </CardBody>
                                    <CardFooter className='w-100'>
                                        <ButtonGroup spacing='2'>
                                            <Button variant='solid' colorScheme='orange'>
                                                Buy now
                                            </Button>
                                            <Button variant='ghost' colorScheme='orange'>
                                                Add to cart
                                            </Button>
                                        </ButtonGroup>
                                    </CardFooter>
                                </Card>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Cources
