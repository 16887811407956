export const mentorshipPlans = [
    {
        "title": "Beginner's Web Development",
        "price": 2999,
        "duration": "3 months",
        "description": "A comprehensive program covering HTML, CSS, and JavaScript for absolute beginners.",
        "mentor": {
            "name": "Alice Smith",
            "availableTime": "Mon-Fri, 10 AM - 4 PM",
            "chargesPerHour": 500
        },
        "features": [
            "1-on-1 mentoring sessions",
            "Hands-on projects",
            "Access to exclusive resources"
        ],
        "interviewPreparationCharges": 1500,
        "registrationStartDate": "2024-10-01",
        "registrationEndDate": "2024-11-01"
    },
    {
        "title": "Full Stack Development Bootcamp",
        "price": 5999,
        "duration": "6 months",
        "description": "Dive into both front-end and back-end development, including React and Node.js.",
        "mentor": {
            "name": "John Doe",
            "availableTime": "Mon-Sat, 9 AM - 5 PM",
            "chargesPerHour": 700
        },
        "features": [
            "Weekly group sessions",
            "Portfolio building",
            "Mock interviews and resume reviews"
        ],
        "interviewPreparationCharges": 2000,
        "registrationStartDate": "2024-10-10",
        "registrationEndDate": "2024-11-15"
    },
    {
        "title": "Advanced Web Technologies",
        "price": 4999,
        "duration": "4 months",
        "description": "Focus on modern frameworks and libraries like Angular and Vue.js.",
        "mentor": {
            "name": "Sarah Lee",
            "availableTime": "Tue-Thu, 11 AM - 3 PM",
            "chargesPerHour": 600
        },
        "features": [
            "Project-based learning",
            "Code reviews",
            "Networking opportunities"
        ],
        "interviewPreparationCharges": 1800,
        "registrationStartDate": "2024-10-15",
        "registrationEndDate": "2024-11-30"
    },
    {
        "title": "Career Guidance and Interview Prep",
        "price": 1999,
        "duration": "1 month",
        "description": "Prepare for interviews and enhance your soft skills with personalized guidance.",
        "mentor": {
            "name": "Michael Brown",
            "availableTime": "Mon-Fri, 1 PM - 6 PM",
            "chargesPerHour": 400
        },
        "features": [
            "Interview coaching",
            "Resume building",
            "Industry insights"
        ],
        "interviewPreparationCharges": 1200,
        "registrationStartDate": "2024-10-05",
        "registrationEndDate": "2024-10-30"
    },
    {
        "title": "E-Learning Platform Access",
        "price": 1499,
        "duration": "6 months",
        "description": "Gain access to a wide range of online courses and tutorials.",
        "mentor": {
            "name": "Emily Johnson",
            "availableTime": "Anytime",
            "chargesPerHour": 0
        },
        "features": [
            "Self-paced learning",
            "Access to premium content",
            "Community support"
        ],
        "interviewPreparationCharges": 0,
        "registrationStartDate": "2024-09-01",
        "registrationEndDate": "2024-12-31"
    },
    {
        "title": "Data Science and Machine Learning",
        "price": 7999,
        "duration": "5 months",
        "description": "Learn data analysis, machine learning algorithms, and practical applications.",
        "mentor": {
            "name": "David Wilson",
            "availableTime": "Mon-Wed, 10 AM - 5 PM",
            "chargesPerHour": 800
        },
        "features": [
            "Hands-on projects",
            "Real-world case studies",
            "Mentorship from industry experts"
        ],
        "interviewPreparationCharges": 2200,
        "registrationStartDate": "2024-10-20",
        "registrationEndDate": "2024-11-25"
    },
    {
        "title": "Digital Marketing Strategies",
        "price": 3999,
        "duration": "3 months",
        "description": "Understand the fundamentals of digital marketing, SEO, and social media strategies.",
        "mentor": {
            "name": "Jessica Taylor",
            "availableTime": "Sat-Sun, 9 AM - 4 PM",
            "chargesPerHour": 650
        },
        "features": [
            "Interactive workshops",
            "Marketing project",
            "Guidance on personal branding"
        ],
        "interviewPreparationCharges": 1600,
        "registrationStartDate": "2024-10-25",
        "registrationEndDate": "2024-11-30"
    },
    {
        "title": "Cloud Computing Fundamentals",
        "price": 4999,
        "duration": "4 months",
        "description": "Explore cloud services, deployment models, and cloud security.",
        "mentor": {
            "name": "Chris Evans",
            "availableTime": "Tue-Fri, 12 PM - 6 PM",
            "chargesPerHour": 700
        },
        "features": [
            "Hands-on labs",
            "Access to cloud platforms",
            "Certification preparation"
        ],
        "interviewPreparationCharges": 1900,
        "registrationStartDate": "2024-11-01",
        "registrationEndDate": "2024-12-01"
    }
];
