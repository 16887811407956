import React from 'react';
import {
    GridItem,
    VStack,
    Heading,
    Text,
    Badge,
    Button,
    Box,
    useColorModeValue
} from '@chakra-ui/react';

const CompetitionCard = ({ competition }) => {
    const { title, description, location, registrationFee, prizes, mentor, start_date, end_date } = competition;

    // Determine the competition status based on start and end dates
    const getStatus = () => {
        const current = new Date();
        const start = new Date(start_date);
        const end = new Date(end_date);

        if (current < start) return "Upcoming";
        else if (current >= start && current <= end) return "Live";
        else return "Ended";
    };

    const status = getStatus();
    const statusColor = status === "Upcoming" ? "green" : status === "Live" ? "blue" : "red";

    return (
        <GridItem borderWidth={1} borderRadius="lg" overflow="hidden" boxShadow="lg" position="relative" bg={useColorModeValue('white', 'gray.800')}>
            {/* Card Header */}
            <Box p={4} bg={useColorModeValue('teal.500', 'teal.600')} color="white" textAlign="center">
                <Heading size="md">{title}</Heading>
            </Box>

            {/* Card Body */}
            <VStack spacing={4} p={4}>
                <Text fontSize="sm" color="gray.600" textAlign="justify">{description}</Text>
                <Text fontWeight="bold">Location: <Text as="span" fontWeight="normal">{location}</Text></Text>
                <Text fontWeight="bold">Registration Fee: <Text as="span" fontWeight="normal">{registrationFee}</Text></Text>
                <Text fontWeight="bold">Prizes: <Text as="span" fontWeight="normal">{prizes}</Text></Text>
                <Text fontWeight="bold">Mentor: <Text as="span" fontWeight="normal">{mentor.name}</Text></Text>
                <Text fontSize="sm" color="gray.500">Available: {mentor.availableTime}</Text>
                <Text fontSize="sm" color="gray.500">Charges per hour: ₹{mentor.chargesPerHour}</Text>

                {/* Status Badge */}
                <Badge
                    position="absolute"
                    top="1rem"
                    right="1.2rem"
                    colorScheme={statusColor}
                    fontWeight="bold"
                    textAlign="center"
                    paddingX={2}
                >
                    {status}
                </Badge>

                {/* Register Button */}
                <Button colorScheme="teal" size="lg" width="full" mt={4}>
                    Register Now
                </Button>
            </VStack>
        </GridItem>
    );
};

export default CompetitionCard;
