import React from 'react';
import { Card } from '@chakra-ui/react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const PopularityCard = ({ curElm }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <Card ref={ref} style={{ width: "18rem", padding: ".8rem 0", background: `${curElm.card_Style.circle}` }}>
            <h1 className='text-center' style={{ color: `${curElm.card_Style.fcolor}` }}>
                {inView ? (
                    <CountUp
                        start={0}
                        end={curElm.number}
                        duration={6}
                        suffix={curElm.symbol}
                    />
                ) : null}
            </h1>
            <h6 className='text-center'>{curElm.title}</h6>
        </Card>
    );
};

export default PopularityCard;
