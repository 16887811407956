import React, { createContext, useContext, useEffect, useState } from 'react'

const User = createContext();

const UserContext = ({ children }) => {
    const [searchClick, setSearchClick] = useState(false);
    const [cart, setCart] = useState([]);

    useEffect(() => {
    }, [searchClick])
    return (
        <User.Provider value={{ searchClick, setSearchClick, cart, setCart }}>
            {children}
        </User.Provider>
    )
}

export default UserContext

export const UserState = () => {
    return useContext(User);
}
