import React from 'react'

const Jobs = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height: "30rem" }}>
            <div>No Vacancies are Available...</div>
        </div>
    )
}

export default Jobs
