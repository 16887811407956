import React from 'react';
import { NavLink } from 'react-router-dom';

const FooterBar = () => {
    return (
        <div className='footer-bar'>
            {/* Home */}
            <NavLink to="/" className='footer-item'>

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 32 32">
                    <path fill="currentColor" d="M16.612 2.214a1.01 1.01 0 0 0-1.242 0L1 13.419l1.243 1.572L4 13.621V26a2.004 2.004 0 0 0 2 2h20a2.004 2.004 0 0 0 2-2V13.63L29.757 15L31 13.428ZM18 26h-4v-8h4Zm2 0v-8a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v8H6V12.062l10-7.79l10 7.8V26Z" />
                </svg>

                <p className='label'>Home</p>
            </NavLink>
            {/* Sale */}
            <NavLink to="/cources" className='footer-item'>

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="black" d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9zm6.82 6L12 12.72L5.18 9L12 5.28zM17 16l-5 2.72L7 16v-3.73L12 15l5-2.73z" />
                </svg>

                <p className='label'>Learning</p>
            </NavLink>
            {/* Category */}
            <NavLink to="/compete" className='footer-item'>

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
                    <path fill="black" d="m226.063 24.22l-9.782 32.624c12.992-2.652 26.423-4.032 40.19-4.032c10.475 0 20.766.82 30.81 2.376l-9.405-30.97h-51.813zm30.406 48.843c-99.627 0-180.19 80.53-180.19 180.156c0 99.624 80.563 180.155 180.19 180.155c99.624 0 180.155-80.53 180.155-180.156S356.094 73.061 256.468 73.061zm0 41.687c76.482 0 138.467 61.985 138.467 138.47c0 76.482-61.985 138.5-138.468 138.5c-76.485 0-138.5-62.018-138.5-138.5c0-76.485 62.015-138.47 138.5-138.47zm-.033 38.938c-54.96 0-99.53 44.54-99.53 99.5s44.57 99.5 99.53 99.5s99.5-44.54 99.5-99.5s-44.54-99.5-99.5-99.5m.032 39.687c33.052 0 59.842 26.79 59.842 59.844c0 33.052-26.79 59.843-59.843 59.843c-33.055 0-59.845-26.79-59.845-59.844s26.79-59.845 59.844-59.845zm0 30.906c-15.993 0-28.97 12.947-28.97 28.94c0 15.99 12.977 28.968 28.97 28.968c15.99 0 28.936-12.977 28.936-28.97c0-15.99-12.945-28.937-28.937-28.937zM114.905 395l-27.844 92.875h46.876l20.28-62.313A201.8 201.8 0 0 1 114.905 395m277.188 5.688a201.3 201.3 0 0 1-39.72 28.468l19.25 58.72h46.907zm-165.03 50.78v36.407h52.092v-35.53a202 202 0 0 1-22.687 1.28c-9.99 0-19.81-.74-29.408-2.156z" />
                </svg>

                <p className='label'>Compete</p>
            </NavLink>
            {/* New Arrival */}
            <NavLink to="/mentorship" className='footer-item'>

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="black" d="M8.487 14.887c.39 0 .706.314.706.7a.703.703 0 0 1-.706.7H5.632a.703.703 0 0 1-.707-.7c0-.386.317-.7.707-.7zm.69-2.593c.39 0 .706.315.706.7a.703.703 0 0 1-.707.7H5.648a.703.703 0 0 1-.706-.7c0-.386.316-.7.706-.7zm3.864-3.46a2.11 2.11 0 0 1 2.118-2.099a2.11 2.11 0 0 1 2.118 2.1a2.115 2.115 0 0 1-2.118 2.103a2.116 2.116 0 0 1-2.118-2.104m6.259 6.559c.1.619-.378 1.18-1.005 1.178h-6.272a1.016 1.016 0 0 1-1.005-1.178c.315-1.942 1.391-3.509 2.796-4.13a2.77 2.77 0 0 0 2.69 0c1.405.621 2.482 2.19 2.796 4.13m-8.712-4.29c-8.38 0-.147-.002-4.941-.002a.703.703 0 0 1-.707-.7c0-.386.317-.7.707-.7l4.941.001c.39 0 .707.314.706.701a.7.7 0 0 1-.706.7m-4.94-2.594a.7.7 0 0 1-.707-.7c0-.386.317-.7.707-.7h4.94c.389 0 .705.313.705.7a.703.703 0 0 1-.706.699zm7.809 10.117a.66.66 0 0 0 .66-.654h7.06v-12.6H2.824v12.599h7.059c0 .361.295.654.66.654zM24 17.972v.957c0 .605-.496 1.096-1.106 1.096H1.106c-.61 0-1.106-.49-1.106-1.096v-.957h1.413V5.357c0-.763.623-1.382 1.394-1.382h18.387c.77 0 1.394.619 1.394 1.382v12.615Z" />
                </svg>

                <p className='label'>Mentorship</p>
            </NavLink>
            {/* opportunity */}
            <NavLink to="/internship" className='footer-item'>
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="black" d="M19.5 6H16V5a2.003 2.003 0 0 0-2-2h-4a2.003 2.003 0 0 0-2 2v1H4.5A2.5 2.5 0 0 0 2 8.5v10A2.5 2.5 0 0 0 4.5 21h15a2.5 2.5 0 0 0 2.5-2.5v-10A2.5 2.5 0 0 0 19.5 6M9 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1H9zm12 13.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 18.5v-6.473l5.842 1.948A.5.5 0 0 0 9 14h6a.5.5 0 0 0 .158-.025L21 12.027zm0-7.494a.5.5 0 0 0-.158.02L14.919 13H9.081l-5.923-1.975a.5.5 0 0 0-.158-.02V8.5A1.5 1.5 0 0 1 4.5 7h15A1.5 1.5 0 0 1 21 8.5z" />
                </svg>
                <p className='label'>opportunity</p>
            </NavLink>
        </div>
    );
};

export default FooterBar;

