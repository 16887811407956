import React from 'react';
import {
    Box,
    Heading,
    Grid
} from '@chakra-ui/react';
import { competitions } from '../Config/compete'; // Import your competitions data
import CompetitionCard from '../Components/CompetitionCard'; // Import the new component

const Compete = () => {
    return (
        <Box p={8}>
            <Heading as="h2" mb={6} textAlign="center">
                Upcoming <span style={{ color: "orange" }}>Competitions</span>
            </Heading>
            <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
                {competitions.map((competition, index) => (
                    <CompetitionCard key={index} competition={competition} /> // Use CompetitionCard for each competition
                ))}
            </Grid>
        </Box>
    );
};

export default Compete;
