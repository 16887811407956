import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, FormControl, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Text, useDisclosure, useSteps, ModalFooter, Stepper } from '@chakra-ui/react';
import { AvailInternship } from '../Config/InternshipAvail';
import Swal from 'sweetalert2'

import axios from 'axios';

const steps = [
    { title: 'Details', description: 'information' },
    { title: 'Qualification', description: 'education details' },
    { title: 'Verify', description: 'Verify Details' },
];

const Internship = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    });

    const [selectedInternship, setSelectedInternship] = useState(null);
    const [message, setMessage] = useState('');
    console.log(message)


    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        phone: '',
        email: '',
        degree: '',
        university: '',
        Resume: '',
        applied: selectedInternship,
    });

    // Update the applied field in formData whenever selectedInternship changes
    useEffect(() => {
        setFormData((prev) => ({ ...prev, applied: selectedInternship }));
    }, [selectedInternship]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name)
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);

        // Ensure that the applied (selected internship) field is correctly updated
        const updatedFormData = { ...formData, applied: selectedInternship };

        console.log("Submitting form data:", updatedFormData); // Debugging info

        try {
            const response = await axios.post(process.env.REACT_APP_MAIL_LINK,

                updatedFormData
            );
            console.log("Server response:", response.data.message);

            // Reset formData after successful submission
            setFormData({
                name: '',
                dob: '',
                phone: '',
                email: '',
                degree: '',
                university: '',
                Resume: '',
                applied: '',
            });

            setMessage(response.data.message);
            setSelectedInternship(null);
            setActiveStep(0); // Reset step to 0
            onClose();

            Swal.fire({
                title: "Success!",
                text: "Your response recorded successfully!",
                icon: "success"
            });
        } catch (error) {
            onClose();
            Swal.fire({
                title: "Error!",
                text: error.response?.data?.message || "Failed to save data",
                icon: "error"
            });
            console.error("Failed to submit data", error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div>
            <div className='container mt-5'>
                <h1 className='text-center mt-lg-5'>Virtual <span style={{ color: "orange" }}>Internships</span> For Remote Learning.</h1>
                <div className='col-12 d-flex flex-wrap gap-5 justify-content-center my-5'>
                    {AvailInternship.map((curElm, index) => (
                        <Card key={index} style={{ width: "18rem", background: `${curElm.card_Style.bgcolor}` }}>
                            <div className='d-flex justify-content-center mt-4'>
                                <div className='d-flex justify-content-center align-items-center' style={{ width: "4rem", height: "4rem", borderRadius: "50%", background: `${curElm.card_Style.circle}` }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 14 14">
                                        <g fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M13 2H1a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-8A.5.5 0 0 0 13 2m-7 9l-1 2.5M8 11l1 2.5m-5 0h6" />
                                            <path d="m4.5 5.25l-1.75 1.5L4.25 8m5.5-2.5l1.5 1.25l-1.75 1.5m-3.25.5l1.5-4.5" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <CardHeader>
                                <Heading className='text-center' size='md'>{curElm.name}</Heading>
                            </CardHeader>
                            <CardBody>
                                <Text>{curElm.description}</Text>
                            </CardBody>
                            <CardFooter className='w-100'>
                                <Button className='m-auto' onClick={() => {
                                    onOpen();
                                    setSelectedInternship(curElm.name)
                                }} style={{ background: `${curElm.card_Style.circle}`, color: `${curElm.card_Style.fcolor}` }}>Register</Button>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>

            {/* Modal */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader >Candidate Registration</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        {/* Stepper */}
                        <Stepper index={activeStep} style={{ scale: ".9" }}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                                    </StepIndicator>
                                    <Box flexShrink='0'>
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepDescription>{step.description}</StepDescription>
                                    </Box>
                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>

                        {/* Form Fields */}
                        {activeStep === 0 && (
                            <>
                                <h6>Apply for : <span style={{ color: "orange" }}> {selectedInternship}</span></h6>
                                <FormControl mt={4}>
                                    <FormLabel>Name</FormLabel>
                                    <Input name="name" type='string' value={formData.name} onChange={handleInputChange} placeholder='Name' />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>DOB</FormLabel>
                                    <Input name="dob" type="date" value={formData.dob} onChange={handleInputChange} placeholder='dob' />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Phone</FormLabel>
                                    <Input name="phone" type='number' value={formData.phone} onChange={handleInputChange} placeholder='phone' />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Email</FormLabel>
                                    <Input name="email" type="email" value={formData.email} onChange={handleInputChange} placeholder='Email' />
                                </FormControl>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <FormControl mt={4}>
                                    <FormLabel>Degree</FormLabel>
                                    <Input name="degree" type='string' value={formData.degree} onChange={handleInputChange} placeholder='Degree' />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>University</FormLabel>
                                    <Input name="university" type='string' value={formData.university} onChange={handleInputChange} placeholder='University' />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Resume link</FormLabel>
                                    <Input name="Resume" type='string' value={formData.Resume} onChange={handleInputChange} placeholder='Resume Link' />
                                </FormControl>
                            </>
                        )}
                        {activeStep === 2 && (
                            <Box>
                                <Text className='text-center' style={{ color: "orange" }}>{selectedInternship}</Text>
                                <Text><strong>Name:</strong> {formData.name}</Text>
                                <Text><strong>DOB:</strong> {formData.dob}</Text>
                                <Text><strong>phone:</strong> {formData.phone}</Text>
                                <Text><strong>Email:</strong> {formData.email}</Text>
                                <Text><strong>Degree:</strong> {formData.degree}</Text>
                                <Text><strong>University:</strong> {formData.university}</Text>
                                <Text><strong>Resume:</strong> {formData.Resume}</Text>

                            </Box>
                        )}

                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={handleBack} isDisabled={activeStep === 0}>
                            Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                            <Button colorScheme='orange' onClick={handleSubmit} ml={3} isLoading={isLoading}>
                                Submit
                            </Button>
                        ) : (
                            <Button variant="outline" colorScheme='orange' onClick={handleNext} ml={3}>
                                Next
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default Internship;
